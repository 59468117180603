<template>
  <div></div>
</template>
<script>
import { saveData } from "@/lib/util";
import xmpp from "@/lib/xmpp";

export default {
  name: 'Logout',
  components: {
  },
  data() {
    return {}
  },
  mounted() {
    xmpp.disconnect();
    saveData('loginId', '');
    this.$store.dispatch('setAppToken', '');
    this.$store.dispatch('setMyInfo', null);
    this.$router.replace('/login');
  },
  methods: {

  }
}
</script>
